/* Copyright G. Hemingway, 2024 - All rights reserved */
"use strict";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Pile } from "./pile.js";

const CardRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 2em;
`;

const CardRowGap = styled.div`
  flex-grow: 2;
`;

const GameBase = styled.div`
  grid-row: 2;
  grid-column: sb / main;
`;

export const Game = () => {
  const { id } = useParams();
  let [state, setState] = useState({
    pile1: [],
    pile2: [],
    pile3: [],
    pile4: [],
    pile5: [],
    pile6: [],
    pile7: [],
    stack1: [],
    stack2: [],
    stack3: [],
    stack4: [],
    draw: [],
    discard: [],
  });
  // let [startDrag, setStartDrag] = useState({ x: 0, y: 0 });
  const [clickedCards, setClickedCards] = useState(null);
  const [source, setSource] = useState(null);
  const [move, setMove] = useState(null);

  useEffect(() => {
    const getGameState = async () => {
      const response = await fetch(`/v1/game/${id}`);
      const data = await response.json();
      setState({
        pile1: data.pile1,
        pile2: data.pile2,
        pile3: data.pile3,
        pile4: data.pile4,
        pile5: data.pile5,
        pile6: data.pile6,
        pile7: data.pile7,
        stack1: data.stack1,
        stack2: data.stack2,
        stack3: data.stack3,
        stack4: data.stack4,
        draw: data.draw,
        discard: data.discard,
      });
    };
    getGameState();
  }, [id]);

  useEffect(() => {
    const sendMove = async (move) => {
      const response = await fetch(`/v1/game/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(move),
      });

      if (response.ok) {
        const newState = await response.json();
        setState(newState);
      } else {
        // reset to previous state on error
        const gameState = await fetch(`/v1/game/${id}`);
        const data = await gameState.json();
        setState(data);
      }
    };
    if (move) {
      console.log(JSON.stringify(move));
      sendMove(move);
    }
  }, [move]);

  const onClick = async (ev, pileName) => {
    const target = ev.target;
    const [suit, value] = target.id.split(":");
    if (!source) {
      // if draw pile
      if (pileName === "draw") {
        let cards = [];
        // if non empty draw pile
        if (state.draw.length > 0) {
          // check how many to draw -- 1 or 3 depending on game settings
          const drawCount = Math.min(state.drawCount || 1, state.draw.length);
          cards = state.draw.slice(-drawCount);
        }
        const move = {
          cards: cards,
          src: "draw",
          dst: "discard",
        };
        setMove(move);
        reset();
        return;
      }

      // if not draw figure out which cards were clicked
      const card = state[pileName].find(
        (card) => card.suit === suit && card.value === value && card.up,
      );
      if (!card) {
        reset();
        return;
      }
      // if bottom most card, this is the card, if not, every card below it is also selected
      const cards = state[pileName].slice(state[pileName].indexOf(card));
      setSource(pileName);
      setClickedCards(cards);
    } else {
      // if not selected source, this is the target pile
      const targetPile = pileName;
      if (targetPile === source) {
        reset();
        return;
      }

      const move = {
        cards: clickedCards,
        src: source,
        dst: targetPile,
      };

      setMove(move);
      reset();
    }
  };

  const handleBackgroundClick = (ev) => {
    if (!ev.target.classList.contains("pile") && ev.target.tagName !== "IMG") {
      reset();
    }
  };

  // escape key
  useEffect(() => {
    const handleEscape = (ev) => {
      if (ev.key === "Escape") {
        reset();
      }
    };
    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, []);

  const reset = () => {
    setClickedCards(null);
    setSource(null);
  };

  return (
    <GameBase onClick={handleBackgroundClick}>
      <CardRow>
        <Pile
          cards={state.stack1}
          spacing={0}
          onClick={(ev) => onClick(ev, "stack1")}
          highlighted={clickedCards}
        />
        <Pile
          cards={state.stack2}
          spacing={0}
          onClick={(ev) => onClick(ev, "stack2")}
          highlighted={clickedCards}
        />
        <Pile
          cards={state.stack3}
          spacing={0}
          onClick={(ev) => onClick(ev, "stack3")}
          highlighted={clickedCards}
        />
        <Pile
          cards={state.stack4}
          spacing={0}
          onClick={(ev) => onClick(ev, "stack4")}
          highlighted={clickedCards}
        />
        <CardRowGap />
        <Pile
          name={"draw"}
          cards={state.draw}
          spacing={0}
          onClick={(ev) => onClick(ev, "draw")}
          highlighted={clickedCards}
        />
        <Pile
          name={"discard"}
          cards={state.discard}
          horizontal={true}
          spacing={0}
          onClick={(ev) => onClick(ev, "discard")}
          highlighted={clickedCards}
        />
      </CardRow>
      <CardRow>
        <Pile
          cards={state.pile1}
          onClick={(ev) => onClick(ev, "pile1")}
          highlighted={clickedCards}
        />
        <Pile
          cards={state.pile2}
          onClick={(ev) => onClick(ev, "pile2")}
          highlighted={clickedCards}
        />
        <Pile
          cards={state.pile3}
          onClick={(ev) => onClick(ev, "pile3")}
          highlighted={clickedCards}
        />
        <Pile
          cards={state.pile4}
          onClick={(ev) => onClick(ev, "pile4")}
          highlighted={clickedCards}
        />
        <Pile
          cards={state.pile5}
          onClick={(ev) => onClick(ev, "pile5")}
          highlighted={clickedCards}
        />
        <Pile
          cards={state.pile6}
          onClick={(ev) => onClick(ev, "pile6")}
          highlighted={clickedCards}
        />
        <Pile
          cards={state.pile7}
          onClick={(ev) => onClick(ev, "pile7")}
          highlighted={clickedCards}
        />
      </CardRow>
    </GameBase>
  );
};

Game.propTypes = {};
